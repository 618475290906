.drawer {
  display: flex;
  position: absolute;
  align-items: center;
  left: 0;
}

.mainContainer {
  direction: ltr;
}

.close {
  background-color: #f2f2f2;
  display: flex;
  align-items: center;
  width: 1vw;
  height: 25vh;
  border-top-right-radius: 0.5vh;
  border-bottom-right-radius: 0.5vh;
}

.arrow {
  font-size: 100px;
}

.botonDescarga2 {
  color: #f2f2f2;
  background: linear-gradient(to right, #217efc, #1b55b5);
}

.filtrosColumn {
  display: flex;
  flex-direction: column;
  align-items: center;
}


