.columns {
  display: flex;
  flex-direction: column;
  gap: 5vh;
  align-items: center;
}

.mainContainer {
  margin-top: 5vh;
  display: flex;
  flex-direction: row;
  width: 80vw;
}

.ruc {
  width: 20vw;
}

.cambios {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  width: 60vw;
}

.cambio {
  width: 17.5vw;
}
