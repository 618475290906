.mainContainer {
  display: flex;
  padding-bottom: 1vh;
  flex-direction: column;
  justify-content: space-around;
  border-radius: 1vh;
  box-shadow: 0 6px 16px rgba(0, 0, 0, 0.2);
  background-color: #ffffff;
  margin-bottom: 2vh;
  max-height: 17.5vh;
  width: 77.5vw;
  transition: rotate 2s ease-in-out;
}

.dosPartes {
  padding-left: 1vw;
  padding-right: 1vw;
  margin-bottom: 0.25vh;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.actionBox {
  width: 7.5vw;
  font-size: 12px;
}

.subtitulo {
  display: flex;
  font-size: 12px;
  font-weight: bold;
  width: 8vw;
  align-items: center;
}

.collapsibleBusqueda {
  border-top-left-radius: 1vh;
  border-top-right-radius: 1vh;
  background-color: #217efc;
}
.collapsibleVictima {
  background-color: #2275d1;
}
.collapsibleOfensor {
  background-color: #1d66a8;
}
.collapsibleConcurrente {
  background-color: #144a72;
}

.collapsible {
  display: flex;
  align-items: center;
  cursor: pointer;
  color: white;
  padding: 0.25vw 0.5vw 0.25vw 0.25vw;
  margin-bottom: 0.5rem;
  justify-content: space-between;
  transition: rotate 2s ease-in-out;
}

.divisorFiltros {
  color: #ffffff;
  font-weight: bold;
  text-indent: 0.75vw;
  margin-top: 0.25vh;
  margin-bottom: 0.25vh;
}

.arrowClose {
  font-weight: bold;
  user-select: none;
  transform: rotate(90deg) scale(1, 1.5);
}

.arrowOpen {
  font-weight: bold;
  user-select: none;
  transform: rotate(-90deg) scale(1, 1.5);
}

.divider {
  border-style: dashed;
  border-width: 1px;
  width: 100%;
  color: rgb(200, 200, 200);
}

.contentContainer {
  padding: 0.25rem;
  display: flex;
  flex-direction: row;
  transition: max-height 0.5s ease-in-out; /* Transition for max-height */
  overflow: hidden; /* Prevent content from overflowing during animation */
  justify-content: space-around;
}

.contentContainer .open {
  max-height: 100%;
}

.contentContainer .closed {
  max-height: 0;
}
