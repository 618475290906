.App {
  font-family: "Lato", sans-serif;
  font-size: 14px;
}

.App-header {
  background-color: #282c34;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.Loading{
  display: flex;
  flex-direction: column;
  width: 100vw;
  height: 100vh;
  align-items: center;
  justify-content: center;
}

.Hash{
  margin-bottom: 5vh;
}

.Bar{
  width: 60vw
}

.logoContainer{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 57.5vw;
  margin-right: 5vw;
  margin-left: 2.5vw;
  margin-top: 5vh;
}

.logoUc{
  height: 17.5vh;
}

.logoSam{
  height: 17.5vh;
}

.logoUnimico{
  height: 17.5vh;
}