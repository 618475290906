.mainContainer {
  width: 100%;
  background-color: #f2f3f4;
}

.encabezado {
  padding-top: 1rem;
  font-size: 24px;
  font-weight: bolder;
  padding-left: 5vw;
  padding-bottom: 1rem;
  background-color: #4d4d4d;
  color: white;
}
/* Datos iniciales */

.infoInicial {
  width: 100%;
  height: 12.5vh;
  padding: 1.5vh 5vw;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background-color: #f2f3f4;
}

.fechaIngreso{
  text-align: right;
}

.infoInicial_columna1 {
  display: flex;
  flex-direction: column;
  font-weight: bold;  
  justify-content: space-around;
}

.infoInicial_columna2 {
  display: flex;
  flex-direction: row;
  font-weight: bold;  
  gap: 1vw;
  width: 30vw;
}

.labelColumna2{
  padding: 0.5vh;
}

.infoInicial_columna2_filas {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.etiquetaCaso {
  background-color: black;
  color: white;
  font-size: 1.5em;
  font-weight: bold;
  padding: 0.5rem;
  text-align: center;
  border-radius: 12px;
}

.etiquetaFechas {
  background: linear-gradient(to right, #fc881a, #ff1267);
  padding: 0.5vh;
  font-weight: normal;
  border: none;
  color: white;
  border-radius: 3px;
  max-width: 5vw;
}

.estado_ofensor {
  background-color: #4abd92;
  padding: 0.5vh;
  color: white;
  max-width: 15vw;
  border-radius: 3px;
}

.estado_victima {
  background-color: #fe5164;
  padding: 0.5vh;
  color: white;
  max-width: 15vw;
  border-radius: 3px;
}

/* Datos de ingreso */
.collapsible {
  display: flex;
  align-items: center;
  cursor: pointer;
  background-color: #217efc;
  color: white;
  padding: 0.25vw 0.5vw 0.25vw 0.25vw;
  justify-content: space-between;
  transition: rotate 2s ease-in-out;
}

.arrowClose{
  font-size: 16px;
  font-weight: bold;
  transform: rotate(90deg) scale(1,1.5);
  right: 1vw;
}

.arrowOpen{
  font-size: 16px;
  font-weight: bold;
  transform: rotate(-90deg) scale(1,1.5);
  right: 1vw;
}

.divisorFiltros {
  background-color: #217efc;
  color: #ffffff;
  font-weight: bold;
  font-size: 1rem;
  text-indent: 5vw;
}

.editor {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 1vh;
}

.infoVictima_card {
  background-color: #ffffff;
  padding: 0.75rem;
  margin-bottom: 1rem;
}

.infoVictima_card:hover {
  cursor: pointer;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
  transform: translateY(-2px);

  transition: all 0.2s ease-in-out;
}

.infoVictima_card_name {
  background-color: #4d4d4d;
  color: white;
  padding: 0.25rem;
}

.infoVictima_card_rut {
  background-color: #ebeced;
  padding: 0.25rem;
}

.row_div {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.edit {
  height: 2vh;
  margin-left: 1vw;
}

.saveIcon {
  cursor: pointer;
  text-align: center;
  font-size: 2vw;
  font-weight: bolder;
  line-height: 4vw;
  position: fixed;
  right: 6vw;
  top: 12vh;
  height: 4vw;
  color: white;
}

.save:hover,
.clipIcon:hover {
  font-size: 2.5vw;
}

.saveIcon:hover {
  height: 4.25vw;
}

.attachIcon {
  cursor: pointer;
  text-align: center;
  font-size: 2vw;
  font-weight: bolder;
  line-height: 4vw;
  position: fixed;
  right: 6vw;
  top: 22vh;
  height: 4vw;
  border-radius: 50%;
}

.attachIcon:hover {
  height: 4.25vw;
}

.tabla_premediacion_div {
  display: flex;
  justify-content: center;
  margin-top: 1rem;
}

table {
  border-collapse: collapse;
  width: 50vw;
  font-size: 16px;
  color: #333;
}

thead th:nth-child(even) {
  background-color: #1c4c91;
  color: white;
}

thead th:nth-child(odd) {
  background-color: #3366cc;
  color: white;
}

tbody tr:nth-child(even) th {
  background-color: #f2f2f2;
}

tbody tr:nth-child(odd) th {
  background-color: #d9d9d9;
}

tbody td {
  padding: 8px;
  border: 1px solid #ccc;
}

tbody td:first-child {
  text-align: center;
}

th {
  padding: 8px;
  border: 1px solid #ccc;
}

.datos_usuarios_premediacion {
  margin-left: 5rem;
  margin-bottom: 8px;
}

.tableRow {
  cursor: pointer;
}

.tableRow:hover {
  cursor: pointer;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
  transform: translateY(-2px);

  transition: all 0.2s ease-in-out;
}

.boton_volver {
  background-color: #3366cc;
  color: white;
  border: none;
  width: 10rem;
  padding: 0.15rem;
  border-radius: 3px;
  margin-left: 5rem;
  margin-top: 1rem;
}

.boton_volver:hover {
  cursor: pointer;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
  transform: translateY(-2px);
  transition: all 0.2s ease-in-out;
}

/* CSS MODAL GUARDADO */

.guardarModalContainer {
  position: fixed;
  background-color: #dcece4;
  height: auto;
  width: 40vw;
  top: 50%;
  z-index: 1001;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 12px;
}

.encabezadoGuardar {
  padding-top: 1rem;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  font-size: 16px;
  font-weight: bolder;
  border-top-right-radius: 12px;
  border-top-left-radius: 12px;
  text-align: center;
  padding-bottom: 1rem;
  background-color: #4d4d4d;
  color: white;
  margin-bottom: 1rem;
}

.botones_modales_verificacion {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-top: 2rem;
  justify-content: center;
  align-items: center;
}

.enunciado_modal {
  padding: 0rem 2rem;
  font-size: 14px;
  font-weight: bold;
}

.btn_seguir_en_form {
  margin-top: 2vh;
  /* margin-bottom: 5vh; */
  line-height: 5vh;
  height: auto;
  width: 15vw;
  border: none;
  text-align: center;
  font-weight: bolder;
  font-size: 14px;
  color: #f2f2f2;
  border-radius: 1vh;
  background-color: #2cbe78;
}

.btn_seguir_en_form:hover {
  transform: translateY(-2px);
  box-shadow: 0 6px 16px rgba(0, 0, 0, 0.2);

  cursor: pointer;
}

.btn_cerrar_form {
  margin-top: 2vh;
  margin-bottom: 5vh;
  line-height: 5vh;
  height: 5vh;
  border: none;
  width: 15vw;
  text-align: center;
  font-weight: bolder;
  font-size: 14px;
  color: #f2f2f2;
  border-radius: 1vh;
  background-color: #ff5565;
}

.btn_cerrar_form:hover {
  transform: translateY(-2px);
  box-shadow: 0 6px 16px rgba(0, 0, 0, 0.2);

  cursor: pointer;
}

.btn_guardar_en_form {
  margin-top: 2vh;
  /* margin-bottom: 5vh; */
  line-height: 5vh;
  height: auto;
  width: 15vw;
  border: none;
  text-align: center;
  font-weight: bolder;
  font-size: 14px;
  color: #f2f2f2;
  border-radius: 1vh;
  background-color: #2075ff;
}

.btn_guardar_en_form:hover {
  transform: translateY(-2px);
  box-shadow: 0 6px 16px rgba(0, 0, 0, 0.2);

  cursor: pointer;
}

.btn_descarga_drawer {
  background-color: #0e91d0;
  border: none;
  border-radius: 12px;
  padding: 8px;
  color: white;
}

.btn_descarga_drawer:hover {
  transform: translateY(-2px);
  box-shadow: 0 6px 16px rgba(0, 0, 0, 0.2);

  cursor: pointer;
}

.div_subir_nuevos_documentos {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 16px;
  background-color: #fff;
  margin-bottom: 1rem;
}

.nuevos_documentos {
  overflow-y: auto;
  max-height: 320px;
}

.columna_subir_nuevo {
  display: flex;
  flex-direction: column;
  width: 12rem;
  justify-content: center;
  margin: 0 auto;
}

.subir_nuevo_btn {
  background-color: #20c997;
  border: none;
  border-radius: 5px;
  padding: 8px;
  color: white;
  margin-top: 8px;
  font-weight: bold;
}

.subir_nuevo_btn:hover {
  transform: translateY(-2px);
  box-shadow: 0 6px 16px rgba(0, 0, 0, 0.2);

  cursor: pointer;
}

.fileInput {
  display: none;
}

.fileInputLabel {
  display: inline-block;
  padding: 8px 16px;
  background-color: #2196f3;
  color: #fff;
  font-weight: bold;
  text-align: center;
  border-radius: 5px;
  cursor: pointer;
}

.fileInputLabel:hover {
  background-color: #0e91d0;
}

.btn_descarga_drawer {
  background-color: #0e91d0;
  border: none;
  border-radius: 12px;
  padding: 8px;
  color: white;
}

.btn_descarga_drawer:hover {
  transform: translateY(-2px);
  box-shadow: 0 6px 16px rgba(0, 0, 0, 0.2);

  cursor: pointer;
}

.fileInputLabel1 {
  display: inline-block;
  padding: 8px 16px;
  background-color: #20c997;
  color: #fff;
  font-weight: bold;
  border-radius: 12px;
  width: 4rem;
  cursor: pointer;
  margin: 0 auto;
}

.fileInputLabel1:hover {
  background-color: #20c997;
  transform: translateY(-2px);
  box-shadow: 0 6px 16px rgba(0, 0, 0, 0.2);
}

.attach_drawer {
  height: 2rem;
  width: 2rem;
}

.div_drawer_subir {
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
}

.fileName {
  margin-top: 8px;
  margin-bottom: 8px;
  margin-left: 4px;
}

.subtitulo {
  font-weight: bold;
  font-size: 14px;
  padding-bottom: 0.5rem;
  max-width: 40vw;
}

.subtituloComediador {
  font-weight: bold;
  font-size: 14px;
  max-width: 40vw;
  text-align: center;
}

.div_subir_nuevos_documentos {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 16px;
  background-color: #fff;
  margin-bottom: 1rem;
}

.nuevos_documentos {
  overflow-y: auto;
  max-height: 250px;
}


.gridPartes {
  display: grid;
  grid-template-rows: 5vh 5vh 5vh;
  grid-template-columns: 10vw 5vw 3vw 5vw;
  align-items: center;
}

.mas {
  background-color: #2bc26e;
  height: 4vh;
  width: 4vh;
  line-height: 4vh;
  text-align: center;
  border-radius: 50%;
  cursor: pointer;
  user-select: none;
}

.mas:hover {
  transform: translateY(-2px);
  box-shadow: 0 6px 16px rgba(0, 0, 0, 0.2);
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
}

.menos {
  background-color: #ff636e;
  height: 4vh;
  width: 4vh;
  line-height: 4vh;
  text-align: center;
  border-radius: 50%;
  cursor: pointer;
  user-select: none;
}

.menos:hover {
  transform: translateY(-2px);
  box-shadow: 0 6px 16px rgba(0, 0, 0, 0.2);
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
}

.actionBox {
  width: 15vw;
  margin-bottom: 0.5rem;
}

.error {
  border: 2px solid red;
}

.mensaje_error_datos {
  font-size: 12px;
  color: red;
}

.agregar_premediacion {
  display: flex;
  gap: 10rem;
  justify-content: center;
}

.body_columna {
  background-color: #ebeff5;
  padding: 2.5rem;
  overflow-y: auto;
}

.bold {
  font-weight: bold;
}

.boton_cerrar {
  background-color: #4d4d4d;
  color: white;
  border: none;
  cursor: pointer;
  padding: 0.4rem;
  border-radius: 3px;
}

.boton_cerrar_deshabilitado {
  background-color: #a5a5a5;
  color: #c7c7c7;
  border: none;
  padding: 0.4rem;
  cursor: pointer;
  opacity: 0.5;
  border-radius: 3px;
}

.boton_paso_fase {
  background-color: #4d4d4d;
  color: white;
  border: none;
  cursor: pointer;
  padding: 0.4rem;
  border-radius: 3px;
}

.boton_paso_fase_deshabilitado {
  background-color: #a5a5a5;
  color: #c7c7c7;
  cursor: not-allowed;
  border: none;
  padding: 0.4rem;
  border-radius: 3px;
}

.boton_cerrar:hover,
.boton_cerrar_deshabilitado:hover,
.boton_paso_fase_deshabilitado:hover,
.boton_paso_fase:hover {
  cursor: pointer;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
  transform: translateY(-2px);
  transition: all 0.2s ease-in-out;
}

.boton1 {
  background: linear-gradient(to right, #217efc, #1b55b5);
  color: white;
  border: none;
  width: 10rem;
  padding: 0.15rem;
  border-radius: 3px;
}

.boton2 {
  background: linear-gradient(to right, #a943d8, #1b41b5);
  color: white;
  border: none;
  width: 10rem;
  padding: 0.15rem;
  border-radius: 3px;
}

.boton3 {
  background: linear-gradient(to right, #fc5164, #a8375d);
  color: white;
  border: none;
  width: 10rem;
  padding: 0.15rem;
  border-radius: 3px;
}

.boton1:hover,
.boton2:hover,
.boton3:hover {
  cursor: pointer;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
  transform: translateY(-2px);
  transition: all 0.2s ease-in-out;
}

.botones_cierre {
  margin-top: 1rem;
  margin-bottom: 1rem;
  text-align: center;
  display: flex;
  justify-content: space-evenly;
}

.container_usuarios {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.columna_victima {
  margin-left: 5rem;
  background-color: #ebeff5;
  width: 32vw;
}

.columna_ofensor {
  margin-right: 5rem;
  background-color: #ebeff5;
  width: 32vw;
}

.convocatoriaVictima_card {
  background-color: white;
  padding: 0.75rem;
  margin-bottom: 1rem;
  border-radius: 3px;
}

.convocatoriaVictima_card_row1,
.convocatoriaVictima_card_row2 {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.disclaimers_premediacion {
  margin-left: 18rem;
}

.etiquetas_card {
  background-color: #ebeced;
  padding: 0.25rem;
  max-width: 12rem;
  border-radius: 3px;
}

.etiqueta_titulo {
  font-weight: bold;
}

.header_victima {
  background-color: #3366cc;
  color: white;
  padding: 0.5rem;
}

.header_ofensor {
  background-color: #1c4c91;
  color: white;
  padding: 0.5rem;
}