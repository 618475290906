.container{
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  text-align: center;
  border-radius: 1rem;
  width: 12.5vw;
  height: 10vh;
}

.titulo{
  font-size: 0.8rem;
  font-weight: bold;
}

.numero{
  font-size: 1.5rem;
  font-weight: bolder;
}