.drawer{
  display: flex;
  position: absolute;
  align-items: center;
  left: 0;
}

.CasosMain {
  background-color: blanchedalmond;
  width: 100vw;
  height: 85vh;
}

.CasosFilterBar {
  height: 7.5vh;
  width: 100vw;
  background-color: gray;
  color: white;
  display: flex;
  flex-direction: row;
  text-align: left;
  align-items: center;
  padding-left: 2rem;
  gap: 1vw;
  font-weight: 500;
}

.CasosPanel {
  display: grid;
  grid-template-columns: 20vw 20vw 20vw 20vw 20vw;
  width: 100vw;
  height: 77.5vh;
}

.cerrarSesion{
  position: absolute;
  right: 1vw;
  width: 10vw;
  height: 5vh;
  background-color: #ffffff;
  border-width: 1px;
  border-style: solid;
  border-radius: 5px;
  font-size: 13px;
}

.cerrarSesion:hover{
  font-size: 14px;
  box-shadow: 1.5px 1.5px 2px 2px rgba(0, 0, 0, 0.16);
  cursor: pointer;
}

.loading{
  position: absolute;
  top: 45vh;
  left: 50vw
}

.botonDescarga2 {
  background: linear-gradient(to right, #217efc, #1b55b5);
}