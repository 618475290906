.mainContainer{
  display: flex;
  flex-direction: row;
  gap: 8px;
  justify-content: space-evenly;
  cursor: pointer;
  padding: 0.5rem 1rem;
  border-radius: 1rem;
  background-color: #f7f7f7;
  height: auto;
}

.rucTitle{
  font-size: 0.6rem;
  text-align: center;
  width: auto;
  padding: 0.1rem;
  width: 5rem;
  color: white;
  border-radius: 1rem;
  margin-bottom: 0.5vh;
}

.mainContainer:hover{
  border: solid 2px gray;
}

.containerRow{
  display: flex;
  flex-direction: row;
  gap: 1.6rem;
}

.row{
  width: 10vw;
  font-size: 0.8rem;
}
.row2{
  width: 10rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 1rem;
}
.bold{
  font-weight: bolder;
}
.value_box{
  background-color: white;
  color: black;
  font-weight: bold;
  font-size: 0.8rem;
  padding: 8px;
}