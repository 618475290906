.cajaFiltros{
  margin-top: 1vh;
  overflow-y: scroll;
  overflow-x: hidden;
  width: 20vw;
  height: 75vh;
}
.rowContainer {
  display: flex;
  flex-direction: row;
  width: 100vw;
  height: 75vh;
  margin-left: 1vw;
}

.mainContainer {
  display: flex;
  flex-direction: column;
  width: 75vw;
  height: 75vh;
  margin-left: 1vw;
  overflow-y: scroll;
  overflow-x: hidden;
}

.emptyCard {
  width: 12.5vw;
}

.track {
  display: flex;
  flex-direction: row;
}

.satisfaccionTitle {
  width: 76.5vw;
  font-size: 1.25rem;
  margin-top: 2vh;
  font-weight: bold;
  color: white;
  background: linear-gradient(to right, #217efc, #1b55b5);
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  padding-left: 1vw;
  padding-top: 0.25vh;
  padding-bottom: 0.25vh;
}

.flujoTitle {
  width: 76.5vw;
  font-size: 1.25rem;
  margin-top: 2vh;
  font-weight: bold;
  color: white;
  background: linear-gradient(to right, #ff7c26, #fc5164);
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  padding-left: 1vw;
  padding-top: 0.25vh;
  padding-bottom: 0.25vh;
}

.mediadoresTitle {
  width: 76.5vw;
  font-size: 1.25rem;
  margin-top: 2vh;
  font-weight: bold;
  color: white;
  background: linear-gradient(to right, #a943d8, #1b41b5);
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  padding-left: 1vw;
  padding-top: 0.25vh;
  padding-bottom: 0.25vh;
}

.indicadoresContainer{
  padding-top: 3vh;
  padding-bottom: 3vh;
  padding-left: 1vw;
  width: 80vw;
  background-color: #F2F2F2;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  box-shadow: 10px 10px 16px rgba(0, 0, 0, 0.2);
}

.globalTitle{
  margin-bottom: 2vh;
  font-weight: bold;
  font-size: large;
}

.participanteTitle{
  margin-top: 2vh;
  margin-bottom: 2vh;
  font-weight: bold;
  font-size: large;
}

.cajaFiltros::-webkit-scrollbar {
  border-radius: 12px;
  width: 10px; 
}

.cajaFiltros::-webkit-scrollbar-thumb {
  border-radius: 12px;
  background-color: #217efc;
}

.mainContainer::-webkit-scrollbar {
  border-radius: 12px;
  width: 10px; 
}

.mainContainer::-webkit-scrollbar-thumb {
  border-radius: 12px;
  background-color: #217efc;
}
