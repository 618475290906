.mainContainer {
  display: flex;
  flex-direction: row;
  width: 100vw;
  height: 85vh;
}

.mainCollapsibleContainer {
  display: flex;
  padding-bottom: 1vh;
  flex-direction: column;
  justify-content: space-around;
  border-radius: 1vh;
  box-shadow: 0 6px 16px rgba(0, 0, 0, 0.2);
  background-color: #ffffff;
  margin-bottom: 2vh;
  max-height: 75vh;
  width: 77.5vw;
  transition: rotate 2s ease-in-out;
}

.filterAndRows {
  display: flex;
  flex-direction: column;
  padding-left: 0.5rem;
  padding-right: 0.5vw;
  width: 100vw;
  height: 63vh;
}

.divisorFiltros {
  color: #ffffff;
  font-weight: bold;
  text-indent: 0.75vw;
}

.filtros {
  direction: rtl;
  height: 82vh;
  width: 25vw;
  margin-top: 5vh;
  overflow-y: scroll;
  overflow-x: hidden;
  background-color: #ffffff;
}

.historicoRow {
  min-height: 55vh;
  width: 77.5vw;
}

.pagination {
  display: flex;
  justify-content: center;
  width: 77.5vw;
  height: 5vh;
}

.singleRow {
  padding-top: 1vh;
  padding-left: 1vw;
  padding-right: 1vw;
}

.collapsible {
  border-top-left-radius: 1vh;
  border-top-right-radius: 1vh;
  background-color: #217efc;
  display: flex;
  align-items: center;
  cursor: pointer;
  color: white;
  padding: 0.25vw 0.5vw 0.25vw 0.25vw;
  justify-content: space-between;
  transition: rotate 2s ease-in-out;
}

.arrowClose {
  font-weight: bold;
  user-select: none;
  transform: rotate(90deg) scale(1, 1.5);
}

.arrowOpen {
  font-weight: bold;
  user-select: none;
  transform: rotate(-90deg) scale(1, 1.5);
}

.contentContainer .open {
  max-height: 100%;
}

.contentContainer .closed {
  max-height: 0;
}

.historicoRow .open {
  max-height: 100%;
}

.historicoRow .closed {
  max-height: 0;
}

.pagination .open {
  max-height: 100%;
}

.pagination .closed {
  max-height: 0;
}

.filtros::-webkit-scrollbar {
  border-radius: 12px;
  width: 10px;
}

.filtros::-webkit-scrollbar-thumb {
  border-radius: 12px;
  background-color: #217efc;
}

.botonDescarga {
  position: absolute;
  left: 1vw;
  display: flex;
  flex-direction: row-reverse;
  cursor: pointer;
  height: 5vh;
  margin-top: 0;
  z-index: 100;
  /* background: linear-gradient(to right, #217efc, #1b55b5); */
}

.botonDescarga2 {
  background: linear-gradient(to right, #217efc, #1b55b5);
}
