.card {
  display: grid;
  grid-template-rows: 17.5vh 5vh;
  grid-template-areas:
    "a"
    "b";
  align-items: center;
  justify-content: center;
  text-align: center;
  height: 22.5vh;
  width: 10vw;
  border-radius: 1vh;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
  padding-left: 0.5vw;
  padding-right: 0.5vw;
}

.pieChart {
  grid-area: a;
  height: 17.5vh;
  width: 10vw;
}

.tituloIndicador {
  grid-area: b;
  width: 10vw;
  height: 5vh;
}

.porcentajeIndicadorAzul {
  grid-area: a;
  color: #217efc;
  font-weight: bolder;
}

.porcentajeIndicadorNaranja {
  grid-area: a;
  color: #ff7c26;
  font-weight: bolder;
}

.title {
  height: 5vh;
  font-weight: bold;
}

.indicadorNaranja {
  grid-area: a;
  font-size: xxx-large;
  font-weight: bolder;
  background: linear-gradient(to right, #ff7c26, #fc5164);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.indicadorMorado {
  grid-area: a;
  font-size: xxx-large;
  font-weight: bolder;
  background: linear-gradient(to right, #a943d8, #1b41b5);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.indicadorAzul {
  grid-area: a;
  font-size: xxx-large;
  font-weight: bolder;
  background: linear-gradient(to right, #217efc, #1b55b5);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
