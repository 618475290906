ul {
  margin: 0;
}

.pagination_main {
  display: flex;
  height: 5vh;
}

.pagination_bar {
  display: flex;
  flex-direction: row;
  gap: 0.75rem;
  align-items: center;
  justify-content: right;
}

.page_link {
  cursor: pointer;
}

.active_link {
  background-color: black;
  color: white;
}

li{
  display: block;
}
