.mainContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100vh;
  width: 100vw;
}

.cardContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 50vw;
  height: 80vh;
  margin-top: 10vh;
  box-shadow: 3px 3px 4px 4px rgba(0, 0, 0, 0.16);
  border-radius: 1vw;
}

.logo {
  height: 25vh;
}

.iniciarSesion{
  font-size: 2rem;
  font-weight: 900;
  margin-bottom: 2vh;
}

.inputBox{
  margin-bottom: 2vh;
}

.hintText{
  font-weight: bold;
  margin-bottom: 1vh;
}

.inputField{
  height: 2rem;
  width: 25vw;
  padding-left: 0.5vw;
  border-width: 1px;
  border-style: solid;
  border-color: #217efc;
  border-radius: 5px;
}

.button{
  width: 10vw;
  height: 5vh;
  background: linear-gradient(to right, #217efc, #1b55b5);
  color: #FFFFFF;
  border-width: 1px;
  border-style: solid;
  border-radius: 5px;
  font-size: 13px;
  font-weight: bold;
}

.button:hover{
  font-size: 14px;
  box-shadow: 1.5px 1.5px 2px 2px rgba(0, 0, 0, 0.16);
  cursor: pointer;
}

.logosContainer{
  margin-top: 3vh;
  display: flex;
  width: 50vw;
  max-height: 20vh;
  flex-direction: row;
  justify-content: space-around;
}

.logoFooter{
  object-fit: contain;
  width: 17.5vw;
  text-align: center;
}

.logoUnimico{
  object-fit: contain;
  display: flex;
  flex-direction: column;
  height: 20vh;
  width: 17.5vw;
  align-items: center;
}
.u1{
  object-fit: contain;  
  height: 15vh;
}
.u2{
  object-fit: contain;  
  height: 2.5vh;
}
.u3{
  object-fit: contain;  
  height: 2.5vh;
}
.textoLogo{
  display: flex;
  height: 15vh;
  flex-direction: column;
  justify-content: center;
}

.footer{
  position: absolute;
  text-align: center;
  font-size: 11px;
  width: 40vw;
  bottom: 12.5vh
}
